<template>
    <div class="content-form">
        <a-form :form="form">
            <a-row :gutter="24">
                <a-col :span="4" :lg="6" :xxl="3">
                    <a-form-item label="Tên">
                        <a-input
                            allow-clear
                            v-decorator="['fullName', { initialValue: '' }]"
                            style="width: 100%"
                        />
                    </a-form-item>
                </a-col>

                <a-col :span="4" :lg="6" :xxl="3">
                    <a-form-item label="Số điện thoại">
                        <a-input
                            allow-clear
                            v-decorator="['phone', { initialValue: '' }]"
                            style="width: 100%"
                        />
                    </a-form-item>
                </a-col>

                <a-col :span="4" :lg="6" :xxl="3">
                    <a-form-item label="CMND">
                        <a-input
                            allow-clear
                            v-decorator="['idNumber', { initialValue: '' }]"
                            style="width: 100%"
                        />
                    </a-form-item>
                </a-col>

                <a-col :span="4" :lg="6" :xxl="3">
                    <a-form-item label="Vị trí">
                        <a-select
                            option-filter-prop="children"
                            v-decorator="['position', { initialValue: 'ALL' }]"
                            style="width: 100%"
                        >
                            <a-select-option value="ALL">
                                Tất cả
                            </a-select-option>
                            <a-select-option
                                v-for="position in listPosition"
                                :key="position.id"
                                :value="position.id"
                            >
                                {{ position.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="4" class="form-filter_button">
                    <a-form-item>
                        <a-button
                            type="primary"
                            @click="handleSubmit"
                            icon="search"
                        >
                            Xem kết quả
                        </a-button>
                    </a-form-item>
                </a-col>

                <a-col :span="4" class="form-filter_button">
                    <a-form-item>
                        <a-button @click="handleReset">Xóa tìm kiếm</a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            form: this.$form.createForm(this, { name: 'FormFilter' }),
        };
    },

    computed: {
        ...mapState('source', {
            listSource: 'data',
        }),
        ...mapState('position', {
            listPosition: 'data',
        }),
        ...mapState('round', {
            listRound: 'data',
        }),
    },

    methods: {
        getValueFilter(values) {
            const { position, fullName, phone, idNumber } = values;
            let filterValue = {
                fullName: fullName || null,
                phone: phone || null,
                idNumber: idNumber || null,
            };

            if (position !== 'ALL') {
                filterValue.staffType = {
                    id: position,
                };
            }
            return filterValue;
        },

        handleSubmit() {
            // e.preventDefault();
            this.form.validateFields((err, values) => {
                const filterValue = this.getValueFilter(values);
                this.$emit('click-filter', filterValue);
            });
        },

        handleReset() {
            this.form.resetFields();
            this.$emit('click-filter', {});
        },
    },
};
</script>

<style lang="scss">
.content-form {
    padding: 10px 15px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    /* margin-top: 20px; */

    .ant-form-item {
        margin-bottom: 10px;
        .ant-form-item-label {
            min-width: 140px;
            text-align: left;
            line-height: unset;
        }

        .ant-form-item-control-wrapper {
            width: 100%;
        }
    }

    .form-filter_button {
        width: auto;
        padding-top: 22px;
    }
}
</style>
