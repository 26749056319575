<template>
    <a-layout-content class="CandidateList">
        <a-spin :spinning="isLoading">
            <a-breadcrumb style="margin: 16px 24px">
                <a-breadcrumb-item>Trang chủ</a-breadcrumb-item>
                <a-breadcrumb-item
                    >Danh sách ứng viên không đạt</a-breadcrumb-item
                >
            </a-breadcrumb>
            <div class="content">
                <form-filter @click-filter="handleFilter"></form-filter>
                <!-- @click-export="handleClickExport" -->
                <table-candidate
                    :page="page"
                    :pageSize="pageSize"
                    @change-page="handleChangePage"
                    :candidateData="candidate"
                    @change-status="handleChangeStatus"
                ></table-candidate>
            </div>
        </a-spin>
    </a-layout-content>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormFilter from './FormFilter';
import TableCandidate from './TableCandidate';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
const candidateRepository = RepositoryFactory.get('candidate');

export default {
    name: 'CandidateFail',

    components: { FormFilter, TableCandidate },

    data() {
        return {
            isLoading: false,
            page: 1,
            pageSize: 10,
        };
    },

    created() {
        this.fetchData();
    },

    computed: {
        ...mapState({
            candidate: (state) => state.candidate,
            round: (state) => state.round.data,
            user: (state) => state.authenticate.user,
        }),
    },

    methods: {
        ...mapActions('candidate', {
            fetchCandidate: 'fetch',
        }),

        async fetchData(filter = {}) {
            this.isLoading = true;
            try {
                const params = {
                    ...filter,
                    // status: {
                    //     passed: false,
                    // },
                    isCompleted: true,
                    isPassed: false,
                    page: this.page - 1,
                    pageSize: this.pageSize,
                };
                await this.fetchCandidate(params);
            } catch (error) {
                const { data } = error.response;
                // eslint-disable-next-line no-console
                console.log(data.message);
                this.$error({
                    title: 'Lấy thông tin thất bại!',
                    okText: 'OK',
                    centered: true,
                    onOk: () => {},
                });
            }
            this.isLoading = false;
        },

        handleFilter(values) {
            this.page = 1;
            this.pageSize = 10;
            this.fetchData(values);
        },

        handleChangePage(pagination) {
            this.page = pagination.current;
            this.pageSize = pagination.pageSize;
            this.fetchData();
        },

        async handleChangeStatus({ id, status }) {
            const roundOnline = this.round.find((item) => item.firstStep);
            const roundSalon = this.round.find((item) => item.lastStep);
            let statusID = 0;
            let params = {
                adminId: this.user.userId,
            };
            if (status == 'ONLINE') {
                statusID = roundOnline.statuses.find((status) => status.pending)
                    .id;
                params.round = {
                    id: roundOnline.id,
                };
            }
            if (status == 'SALON') {
                statusID = roundSalon.statuses.find((status) => status.pending)
                    .id;
                params.round = {
                    id: roundSalon.id,
                };
            }

            params.status = {
                id: statusID,
            };

            this.isLoading = true;
            try {
                await candidateRepository.updateStatusInterview(id, params);
                this.$success({
                    title: 'Chuyển ứng viên thành công',
                    okText: 'Tiếp tục',
                    centered: true,
                    onOk: () => {
                        this.fetchData();
                    },
                });
            } catch (error) {
                this.$error({
                    title: 'Chuyển ứng viên thất bại!',
                    okText: 'OK',
                    centered: true,
                    onOk: () => {},
                });
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    min-height: calc(100vh - 120px);
    background-color: #fff;
    margin: 15px 25px;
    padding: 15px;

    .total-card {
        background: #fbfbfb;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        padding: 15px;
        .total {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .title {
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
                line-height: 22px;
            }

            .result {
                color: rgba(0, 0, 0, 0.85);
                font-size: 24px;
                line-height: 32px;
            }
        }
        .total-border {
            border-left: 0.5px solid #ccc;
            border-right: 0.5px solid #ccc;
        }
    }
}
</style>
